import React, {Component} from "react"

export const Collapsible = ({ children }) => (
  <div className="collapsible">
    { children }
  </div>
)

export const CollapsibleContent = ({ children }) => (
  <div className="collapsible-content">
    { children }
  </div>
)

export class CollapsibleTitle extends Component {
  state = {
    isActive: false,
  };

  handleClick = () => {
    this.setState(state => ({isActive: !state.isActive}));
  };

  render() {
    let className = 'collapsible-title clickable';
    className += this.state.isActive ? ' open' : '';

    return (
      <h2 className={className} onClick={this.handleClick}>
        {this.props.children}
        <i />
      </h2>
    )
  }
}
